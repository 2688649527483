import React from 'react';

export default function BreadCrumb() {
  return (
    <div className='breadcrumb flex items-center bg-white'>
      <div className='sm:m-8  container'>
        <ol
          className='font-roboto'
          vocab='https://schema.org/'
          typeof='BreadcrumbList'
        >
          <li property='itemListElement' typeof='ListItem'>
            <a
              className='font-roboto'
              property='item'
              typeof='WebPage'
              href='#'
            >
              <span property='name'>Home</span>
            </a>
            <meta property='position' content='1' />
          </li>
          ›
          <li property='itemListElement' typeof='ListItem'>
            <a
              className='font-roboto'
              property='item'
              typeof='WebPage'
              href='#'
            >
              <span property='name'>
                Looking for a JS Developer to work on…
              </span>
            </a>
            <meta property='position' content='2' />
          </li>
        </ol>
      </div>
    </div>
  );
}
