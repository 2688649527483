import React from 'react';
import Layout from '../components/layout';
import BreadCrumb from '../components/jobDetail/breadCrumb';
import Detail from '../components/jobDetail/detail';

export default function JobDetail() {
  return (
    <Layout>
      <div className='job-detail'>
        <BreadCrumb />
        <div className='container'>
          <div className='grid grid-cols-4 gap-4 xl:gap-14 grid-mt-80'>
            <div className='col-span-4 xl:col-span-3 pb-16'>
              <Detail />
            </div>
            <div
              className='col-span-4 xl:col-span-1 pb-16'
              style={{ height: 500, background: '#5a5e5e' }}
            ></div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
