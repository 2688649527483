import React from 'react';

export default function Detail() {
  return (
    <div className='shadow rounded-lg bg-white p-8'>
      <h2 className='font-roboto job-title'>
        Looking for a JS Developer to work on a website and adjust a few things
        on the server
      </h2>
      <div className='mt-5'>
        <span className='cl-meta'>
          Posted
          <span className='mx-1.5'>2 hours ago</span>-
          <span className='cl-meta-2 mx-1.5'>Public Project</span>
        </span>
      </div>
      <div className='mt-5'>
        <div className='grid grid-cols-3 gap-4 xl:gap-10'>
          <div className='col-span-3 xl:col-span-2 pr-8'>
            <p className='mb-5'>
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
              erat, sed diam voluptua. At vero eos et accusam et justo duo
              dolores et ea rebum. Stet clita kasd gubergren, no sea takimata
              sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit
              amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor
              invidunt ut labore et dolore… Lorem ipsum dolor sit amet,
              consetetur sadipscing elitr, sed diam nonumy eirmod tempor
              invidunt ut labore et dolore magna aliquyam erat, sed diam
              voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
              Stet clita kasd gubergren, no sea takimata sanctus est
            </p>
            <p>
              Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur
              sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore
              et dolore…Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
              sed diam nonumy eirmod tempor invidunt ut labore et dolore magna
              aliquyam erat, sed diam voluptua. At vero eos et accusam et justo
              duo dolores et ea rebum. Stet clita kasd gubergren, no sea
              takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor
              sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod
              tempor invidunt ut labore et dolore… Lorem ipsum dolor sit amet,
              consetetur sadipscin
            </p>
          </div>
          <div className='col-span-3 xl:col-span-1'>
            <div style={{ height: 200, background: '#eee' }}></div>
          </div>
        </div>
      </div>
    </div>
  );
}
